import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Typography from '@material-ui/core/es/Typography/Typography'
import styles from './styles'

class QuizMessage extends React.Component {
  constructor() {
    super()
    this.state = {
      message: 'Quiz finalizado! Você receberá um email com o resultado do processo.'
    }
  }

  componentDidMount() {}

  render() {
    return (
      <main>
      <div>
        <div className="flex flex-col">
          <div>
            <Typography variant="body1">
              {this.state.message}
            </Typography>
          </div>
        </div>
      </div>
    </main>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(QuizMessage))
