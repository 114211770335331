import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  // Checkbox,
  Radio,
  RadioGroup,
  // TextField,
} from '@material-ui/core'

import { Loading } from 'components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import InovaApi from 'helpers/apiRequest'
import moment from 'moment'
import { isNull } from 'lodash'
import Timer from './Timer'
import styles from './styles'


class QuizContent extends React.Component {
  state = {
    currentQuestion: 0,
    disabledInput: false,
    quiz: {
      questions: [],
      quiz_key: '',
      loaded: false,
    },
    showLoading: false,
    userAnswers: [],
    valueCpf: '',
    signature: queryString.parse(this.props.location.search).signature,
    quizResult: null,
    redirectUrl: null,
    quizType: '',
    allQuizzes: [],
    quizStartTime: null,
    seconds: 20,
    popTimer: false,
    extraQuestion: false,
  }

  api = new InovaApi()

  resetState = () =>
    this.setState({
      currentQuestion: 0,
      quiz: {
        questions: [],
        quiz_key: '',
        loaded: false,
      },
      showLoading: false,
      userAnswers: [],
    })

  resetQuiz = async () => {
    // console.log('aqui')
    await this.resetState()
    await this.setState({
      quizResult: null,
    })
  }

  componentDidMount() {
    this.startQuiz()
  }

  onChangeAnswer = async e => {
    const { userAnswers, currentQuestion } = this.state

    userAnswers[currentQuestion] = { ...userAnswers[currentQuestion], value: e.target.value }
    await this.setState({ userAnswers })
    // console.log(this.state.userAnswers)
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeCheckbox = name => event => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  onClickNextQuestion = () => {
    const { userAnswers, currentQuestion } = this.state

    userAnswers[currentQuestion] = { ...userAnswers[currentQuestion], timer: moment() }

    if (typeof userAnswers[currentQuestion].value === 'undefined') return
    if (userAnswers[currentQuestion].value === '') return

    this.setState({ currentQuestion: currentQuestion + 1, userAnswers })
  }

  startQuiz = async () => {
    const { signature } = this.state
    try {
      if (signature !== isNull) {
        await this.setState({ showLoading: true, disabledInput: true })

        const res = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_QUIZ_API}/signed/url/questions?signature=${signature}`,
        })

        const allQuestions = res.data.questions

        const userAnswers = []

        allQuestions.map(() => {
          return userAnswers.push({ value: '', timer: moment() })
        })

        await this.setState({
          quiz: {
            questions: res.data.questions,
            quiz_key: res.data.quiz_key,
            loaded: true,
          },
          userAnswers,
          quizStartTime: moment(),
        })
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.msg_infos) {
          toast.warn(`${error.response.data.msg_infos[0].msg}`)
        }

        if (error.response.data.errors) {
          toast.warn(`${error.response.data.errors[0].msg}`)
        }

        const redirect_url = error.response.data.redirect_url ? error.response.data.redirect_url : process.env.REACT_APP_REDIRECT_ERROR_URL

        setTimeout(() => {window.location.replace(`${redirect_url}`)}, 2000);
      }
    } finally {
      this.setState({ showLoading: false, disabledInput: false })
    }
  }

  onSubmitQuiz = async timeSeconds => {
    const { userAnswers, quiz, quizStartTime, currentQuestion } = this.state

    try {
      if (userAnswers.length !== quiz.questions.length) return

      const answers = userAnswers.map((item, index) => {
        let answer = {}
        if (index === 0) {
          answer = {
            value: item.value,
            timer: Math.floor(item.timer.diff(quizStartTime) / 1000) + 1,
          }
          return answer
        }
        if (index === quiz.questions.length - 1) {
          answer = {
            value: item.value,
            timer: Math.floor(moment().diff(userAnswers[index - 1].timer) / 1000) + 1,
          }
          return answer
        }
        answer = {
          value: item.value,
          timer: Math.floor(item.timer.diff(userAnswers[index - 1].timer) / 1000) + 1,
        }
        return answer
      })
      let data = null
      if (timeSeconds === 0) {
        answers[currentQuestion].value = ''
        const finalAnswer = answers
          .map((item, index) => {
            if (index === currentQuestion) {
              return item
            }
            if (item.value !== '') {
              return item
            }
            return false
          })
          .filter(item => item !== false)
          .map(item => {
            if (item.value === '') {
              // TODO - Fixing counter error
              return {
                value: item.value,
                // timer: Math.abs(item.timer) + seconds,
                timer: 35,
              }
            }
            return item
          })

        data = {
          quiz_key: quiz.quiz_key,
          answers: finalAnswer,
          signature: this.state.signature,
          timer: Math.floor(moment().diff(quizStartTime) / 1000) + 1,
        }
      }
      if (timeSeconds !== 0) {
        data = {
          quiz_key: quiz.quiz_key,
          answers,
          signature: this.state.signature,
          timer: Math.floor(moment().diff(quizStartTime) / 1000) + 1,
        }
      }

      await this.setState({ showLoading: true })
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_QUIZ_API}/signed/url/questions/result`,
        data,
      })

      if (res.data.questions && res.data.questions.length > 0) {
        const allQuestions = res.data.questions

        const extraAnswers = []

        allQuestions.map(() => {
          return extraAnswers.push({ value: '', timer: moment() })
        })

        return await this.setState({
          extraQuestion: true,
          quiz: {
            questions: res.data.questions,
            quiz_key: res.data.quiz_key,
            loaded: true,
          },
          userAnswers: extraAnswers,
          quizStartTime: moment(),
          showLoading: false,
          currentQuestion: 0,
          popTimer: false,
        })
      }

      await this.setState({
        redirectUrl: process.env.REACT_APP_REDIRECT_ERROR_URL,
        showLoading: false,
      })

      return this.resetState()
    } catch (error) {
      if (error.response) {
        toast.warn('Houve um erro ao enviar as respostas!')
      }

      const redirect_url = error.response.data.redirect_url ? error.response.data.redirect_url : process.env.REACT_APP_REDIRECT_ERROR_URL

      setTimeout(() => {window.location.replace(`${redirect_url}`)}, 2000);
    }
  }

  renderQuestion = questions => {
    const { userAnswers, currentQuestion } = this.state
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ fontSize: 16, color: '#333' }}>
          {questions[currentQuestion].title}
        </FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="full"
          onChange={this.onChangeAnswer}
          value={userAnswers[currentQuestion].value}
        >
          {questions[currentQuestion].answers.map((answer, i) => (
            <FormControlLabel
              value={
                answer === 'Verdadeiro' || answer === 'Falso' ? (answer === 'Verdadeiro' ? 'true' : 'false') : answer
              }
              key={i}
              control={(
                <Radio
                  style={{
                    color: '#CB194C',
                  }}
                />
)}
              label={answer}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  renderQuiz = () => {
    const { userAnswers, quiz, currentQuestion, seconds, popTimer } = this.state
    const { classes } = this.props

    return (
      <div>
        {this.renderQuestion(quiz.questions)}
        <div style={{ marginTop: '10px' }}>
          {currentQuestion + 1 === quiz.questions.length ? (
            <div className={classes.container}>
              <Button
                disabled={
                  typeof userAnswers[currentQuestion].value === 'undefined' || userAnswers[currentQuestion].value === ''
                }
                color="primary"
                variant="contained"
                onClick={() => this.onSubmitQuiz()}
              >
                Enviar
              </Button>
              <Timer
                seconds={seconds}
                question={currentQuestion}
                popAction={this.onSubmitQuiz}
                popTimer={() => this.setState({ popTimer: !popTimer })}
              />
            </div>
          ) : (
            <div className={classes.container}>
              <Button color="primary" variant="contained" onClick={this.onClickNextQuestion}>
                Próxima
              </Button>
              <Timer
                seconds={seconds}
                question={currentQuestion}
                popAction={this.onSubmitQuiz}
                popTimer={() => this.setState({ popTimer: !popTimer })}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderQuizResult = () => {
    const { redirectUrl } = this.state
    return ( (redirectUrl !== null) ?
      <div style={{ marginTop: 30 }}>
        <Loading visible={true} />
        {<Redirect to="/quiz/signed/url/message" />}
      </div>
      :
      null
    )
  }

  render() {
    const { quiz, showLoading } = this.state
    return (
      <main style={{ paddingBottom: 20 }}>
        {showLoading ? (
          <div style={{ marginTop: 30 }}>
            <Loading visible={true} />
          </div>
        ) : null}

        {!showLoading && quiz.loaded ? (
          <div style={{ marginTop: 30 }}>{quiz.loaded ? this.renderQuiz() : null}</div>
        ) : null}

        {!showLoading && this.state.redirectUrl !== null ? <div style={{ marginTop: 30 }}>{this.renderQuizResult()}</div> : null}
      </main>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(QuizContent))
