import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import QuizContent from 'pages/QuizContent'
import QuizMessage from 'pages/QuizMessage'
import QuizError from 'pages/QuizError'
import PrivateRoute from './PrivateRoute'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/quiz/signed/url/questions" component={QuizContent} title="Quiz" />
      <PrivateRoute path="/quiz/signed/url/message" component={QuizMessage} title="Quiz concluído" />
      <PrivateRoute path="/quiz/signed/url/error" component={QuizError} title="Erro" />
      <Route path="*" component={() => <h1>Esta página não existe!</h1>} />
    </Switch>
  </BrowserRouter>
)

export default Routes
