export default (theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
  },
  paper: {
    padding: '20px',
    marginBottom: '30px',
  },
  responseItem: {
    textTransform: 'capitalize',
  },
  textField: {
    width: 300,
    marginTop: 15,
  },
  linkButton: {
    backgroundColor: '#CB194C',
    color: '#FFF !important',
    padding: '6px 16px',
    fontSize: '1.4rem',
    minWidth: 64,
    boxSizing: 'border-box',
    minHeight: 36,
    lineHeight: 1.3125,
    fontFamily: 'Muli, Roboto, "Helvetica", Arial, sans-serif',
    fontWeight: 600,
    borderRadius: 4,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
      backgroundColor: '#bf1343',
    },
  },
  formControl: {
    width: 300,
  },
  timer: {
    fontSize: 20,
    marginLeft: 300,
    padding: 10,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    width: 70,
    height: 70,
    borderRadius: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -20,
  },
})
