import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

import {
  AppBar,
  Toolbar,
  Popper,
  Grow,
  Paper,
  Typography,
  // InputBase,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery,
  IconButton,
  Drawer,
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'

import styles from './styles'

const MainNavbar = ({ groups }) => {
  const classes = styles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const theme = useTheme()

  const [openDrawer, setOpenDrawer] = React.useState(false)

  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  function handleClose(event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      keepMounted
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                classes={{
                  root: classes.dropdown,
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
      >
        <Toolbar className={classes.toolbarWrapper}>
          <div
            className={classes.toolbar}
          >
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1170px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <img
                    className={classes.logoImg}
                    src="/assets/images/logos/porto_seguro.png"
                    alt="porto"
                  />
                <Typography
                  className={classes.title}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                   - Quiz
                </Typography>
              </div>
            </div>

            <div
              className={classes.menubar}
            >
              <div className={classes.sectionDesktop}>
                {useMediaQuery('(min-width: 1170px)')}
              </div>

              {useMediaQuery('(min-width: 1170px)')}

            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {useMediaQuery('(max-width: 1170px)')}
          {useMediaQuery('(max-width: 1170px)')}
        </Drawer>
      </AppBar>
      {renderMenu}
    </div>
  )
}

MainNavbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default MainNavbar
